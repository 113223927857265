import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "switch" }
const _hoisted_2 = ["checked"]

import { useStore } from "vuex";
import { computed } from "vue";

import { IconDefinition, library } from "@fortawesome/fontawesome-svg-core";
import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";


export default /*@__PURE__*/_defineComponent({
  __name: 'DarkModeComponent',
  setup(__props) {

const store = useStore();
const isDarkMode = computed(() => store.getters.isDarkMode);

library.add(faMoon as IconDefinition);
library.add(faSun as IconDefinition);

const toggleDarkMode = async () => {
  await store.dispatch("toggleDarkMode");
  console.debug("Store state:", store.state);
  console.debug(isDarkMode.value);
  if (isDarkMode.value) {
    document.documentElement.classList.add("dark");
    console.log("dark mode");
  } else {
    document.documentElement.classList.remove("dark");
    console.log("light mode");
  }
};

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _createElementVNode("input", {
      type: "checkbox",
      onChange: toggleDarkMode,
      checked: !isDarkMode.value
    }, null, 40, _hoisted_2),
    _createElementVNode("span", {
      class: _normalizeClass(["slider round border-2", [
        isDarkMode.value
          ? 'bg-gray-700 border-gray-600'
          : 'bg-gray-300 border-gray-100',
      ]])
    }, [
      (!isDarkMode.value)
        ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
            key: 0,
            icon: ['fa', 'moon'],
            class: "ml-2 mt-2"
          }))
        : _createCommentVNode("", true),
      (isDarkMode.value)
        ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
            key: 1,
            icon: ['fa', 'sun'],
            class: "text-yellow-400 ml-8 mt-2"
          }))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}
}

})